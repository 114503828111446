var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_payment") } },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-form-model",
                    {
                      ref: "formModel",
                      attrs: {
                        model: _vm.filters,
                        layout: "horizontal",
                        "label-col": { span: 8 },
                        "wrapper-col": { span: 14 },
                        "label-align": "left"
                      }
                    },
                    [
                      _c(
                        "a-row",
                        { attrs: { gutter: 16 } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_payment_date"),
                                    prop: "paymentDate"
                                  }
                                },
                                [
                                  _c("a-range-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      format: _vm.DEFAULT_DATE_FORMAT,
                                      placeholder: [
                                        _vm.$t("lbl_start_date"),
                                        _vm.$t("lbl_end_date")
                                      ]
                                    },
                                    model: {
                                      value: _vm.filters.paymentDate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "paymentDate",
                                          $$v
                                        )
                                      },
                                      expression: "filters.paymentDate"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_payment_number"),
                                    prop: "paymentNumber"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_payment_number")
                                        }
                                      )
                                    },
                                    model: {
                                      value: _vm.filters.paymentNumber,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "paymentNumber",
                                          $$v
                                        )
                                      },
                                      expression: "filters.paymentNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_ujo_number"),
                                    prop: "ujoNumber"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_ujo_number")
                                        }
                                      )
                                    },
                                    model: {
                                      value: _vm.filters.ujoNumber,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "ujoNumber", $$v)
                                      },
                                      expression: "filters.ujoNumber"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_bank_in_out"),
                                    prop: "companyBank"
                                  }
                                },
                                [
                                  _c("a-select", {
                                    attrs: {
                                      options: _vm.bankOptions,
                                      "label-in-value": "",
                                      "allow-clear": "",
                                      "filter-option": false,
                                      "show-search": "",
                                      placeholder: _vm.$t(
                                        "common.select-text",
                                        { text: _vm.$t("lbl_bank_in_out") }
                                      ),
                                      loading: _vm.loading.bank,
                                      "dropdown-match-select-width": false
                                    },
                                    on: { search: _vm.onSearchBank },
                                    model: {
                                      value: _vm.filters.companyBank,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "companyBank",
                                          $$v
                                        )
                                      },
                                      expression: "filters.companyBank"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: {
                                span: 12,
                                xs: 24,
                                sm: 24,
                                md: 24,
                                lg: 12
                              }
                            },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_driver_name"),
                                    prop: "driverName"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_driver_name")
                                        }
                                      )
                                    },
                                    model: {
                                      value: _vm.filters.driverName,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "driverName", $$v)
                                      },
                                      expression: "filters.driverName"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_posting"),
                                    prop: "posting"
                                  }
                                },
                                [
                                  _c("SelectPosting", {
                                    model: {
                                      value: _vm.filters.posting,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "posting", $$v)
                                      },
                                      expression: "filters.posting"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_doc_reference"),
                                    prop: "docReference"
                                  }
                                },
                                [
                                  _c("a-input", {
                                    attrs: {
                                      placeholder: _vm.$t(
                                        "common.search-by-text",
                                        {
                                          text: _vm.$t("lbl_doc_reference")
                                        }
                                      )
                                    },
                                    model: {
                                      value: _vm.filters.docReference,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.filters,
                                          "docReference",
                                          $$v
                                        )
                                      },
                                      expression: "filters.docReference"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        loading:
                                          _vm.loading.download ||
                                          _vm.loading.find,
                                        "data-testid": "payment-reset"
                                      },
                                      on: { click: _vm.handleReset }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_reset")) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading:
                                          _vm.loading.download ||
                                          _vm.loading.find,
                                        "data-testid": "payment-find"
                                      },
                                      on: { click: _vm.handleFind }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("lbl_find")) + " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c(
                    "a-table",
                    {
                      attrs: {
                        size: "small",
                        dataSource: _vm.payments.data,
                        columns: _vm.paymentTableColumns,
                        "row-key": "id",
                        loading: _vm.loading.find,
                        "row-selection": _vm.rowSelection,
                        pagination: {
                          showTotal: function() {
                            return (
                              "" +
                              _vm.$t("lbl_total_items", {
                                total: _vm.payments.totalElements
                              })
                            )
                          },
                          total: _vm.payments.totalElements,
                          showSizeChanger: true,
                          pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                          current: _vm.pagination.page,
                          defaultPageSize: _vm.pagination.limit
                        },
                        scroll: { y: 800 },
                        "row-class-name": function(_record, index) {
                          return index % 2 ? "bg-white" : "bg-gray-light"
                        },
                        "data-testid": "payment-table"
                      },
                      on: { change: _vm.onChangeTable },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("lbl_selected") +
                                        ": " +
                                        _vm.selectedPaymentIds.length
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "nullable",
                          fn: function(text) {
                            return _c("span", {}, [
                              _vm._v(" " + _vm._s(text || "-") + " ")
                            ])
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return [
                              text
                                ? _c("div", [
                                    _vm._v(_vm._s(_vm._f("date")(text)))
                                  ])
                                : _c("div", [_vm._v("-")])
                            ]
                          }
                        },
                        {
                          key: "paymentNumber",
                          fn: function(text, record) {
                            return _c("span", {}, [
                              text
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "text-link",
                                      on: {
                                        click: function($event) {
                                          return _vm.openPaymentDetailModal(
                                            record.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(text) + " ")]
                                  )
                                : _c("span", [_vm._v("-")])
                            ])
                          }
                        },
                        {
                          key: "cashInOutNumber",
                          fn: function(text, record) {
                            return [
                              record.cashInOutId
                                ? _c(
                                    "RouterLink",
                                    {
                                      attrs: {
                                        to: {
                                          name: "trucking.cash-in-out.detail",
                                          params: { id: record.cashInOutId },
                                          query: { payment: true }
                                        },
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("a", { staticClass: "text-link" }, [
                                        _vm._v(" " + _vm._s(text) + " ")
                                      ])
                                    ]
                                  )
                                : _c("span", [_vm._v(_vm._s("-"))])
                            ]
                          }
                        }
                      ])
                    },
                    [
                      _c("template", { slot: "footer" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("common.total-amount-text", {
                                text: _vm.paymentAmountTotal.total
                              })
                            ) +
                            " "
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24, align: "end" } },
                [
                  _c(
                    "a-space",
                    [
                      _vm.$can("create", "trucking-in-out")
                        ? _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                disabled: _vm.selectedPaymentIds.length === 0,
                                "data-testid": "payment-posting"
                              },
                              on: { click: _vm.handlePosting }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_posting")) + " ")]
                          )
                        : _vm._e(),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.download || _vm.loading.find,
                            "data-testid": "payment-download"
                          },
                          on: { click: _vm.handleDownload }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("PaymentDetailModal", {
        attrs: {
          visible: _vm.detailModal.visible,
          paymentNumber: _vm.detailModal.paymentNumber
        },
        on: {
          close: function($event) {
            return _vm.detailModal.reset()
          }
        }
      }),
      _c("PaymentPostingDateModal", {
        attrs: { params: _vm.postingModal.params },
        on: { confirm: _vm.onConfirmPosting },
        model: {
          value: _vm.postingModal.visible,
          callback: function($$v) {
            _vm.$set(_vm.postingModal, "visible", $$v)
          },
          expression: "postingModal.visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }