




import { Option } from "@/models/class/option.class";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SelectPosting extends Vue {
  @Prop({ required: false, default: "All", type: String })
  value!: string;

  options: Array<Option> = [
    {
      key: 0,
      label: this.$t("lbl_yes").toString(),
      value: "true",
    },
    {
      key: 1,
      label: this.$t("lbl_no").toString(),
      value: "false",
    },
    {
      key: 2,
      label: this.$t("lbl_all").toString(),
      value: "",
    },
  ];

  onChange(e: string): void {
    this.$emit("input", e);
  }
}
