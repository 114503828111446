var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: { value: _vm.value, options: _vm.options },
    on: { change: _vm.onChange }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }