




























































































import { useTruckingPayment } from "@/hooks";
import { TruckingPaymentDetailResponseDto } from "@/models/interface/trucking-payment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class PaymentDetailModal extends Vue {
  @Prop({ type: Boolean, required: true, default: false })
  visible!: boolean;

  @Prop({ type: String, required: true, default: "" })
  paymentNumber!: string;

  defaultPaymentDetail: TruckingPaymentDetailResponseDto = {
    amount: 0,
    cashInOutId: "-",
    cashInOutNumber: "-",
    createdBy: "-",
    createdDate: "-",
    description: "-",
    driverId: "-",
    driverName: "-",
    id: "-",
    modifiedBy: "-",
    modifiedDate: "-",
    paymentDate: "",
    paymentNumber: "-",
    sourceBank: "-",
    sourceBankId: "-",
    truckingSecureId: "-",
    ujoNumber: "-",
    driverAccountName: "-",
    driverAccountNumber: "-",
    deposit: 0,
    depositNotes: "-",
  };

  paymentDetail: TruckingPaymentDetailResponseDto = {
    ...this.defaultPaymentDetail,
  };

  loading = false;

  fetchData(paymentNumber: string): void {
    const { findPaymentDetail } = useTruckingPayment();

    this.loading = true;
    findPaymentDetail(paymentNumber)
      .then(res => {
        this.paymentDetail = {
          amount: res.amount,
          cashInOutId: res.cashInOutId,
          cashInOutNumber: res.cashInOutNumber,
          createdBy: res.createdBy,
          createdDate: res.createdDate,
          description: res.description,
          driverAccountName: res.driverAccountName,
          driverAccountNumber: res.driverAccountNumber,
          driverId: res.driverId,
          driverName: res.driverName,
          id: res.id,
          modifiedBy: res.modifiedBy,
          modifiedDate: res.modifiedDate,
          paymentDate: res.paymentDate,
          paymentNumber: res.paymentNumber,
          sourceBank: res.sourceBank,
          sourceBankId: res.sourceBankId,
          truckingSecureId: res.truckingSecureId,
          ujoNumber: res.ujoNumber,
          deposit: res.deposit,
          depositNotes: res.depositNotes,
        };
      })
      .finally(() => {
        this.loading = false;
      });
  }

  handleCancel(): void {
    this.$emit("close");
  }

  @Watch("paymentNumber")
  onPaymentNumberChange(newPaymentNumber: string): void {
    if (!newPaymentNumber) {
      return;
    }

    this.paymentDetail = { ...this.defaultPaymentDetail };
    this.fetchData(newPaymentNumber);
  }
}
