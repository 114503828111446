var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        "destroy-on-close": "",
        centered: "",
        visible: _vm.value,
        "data-testid": "payment-posting-modal",
        "wrapper-col": { span: 24 }
      },
      on: { cancel: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "title",
          fn: function() {
            return [
              _c(
                "span",
                [
                  _c("a-icon", {
                    attrs: {
                      type: "warning",
                      theme: "twoTone",
                      "two-tone-color": "#faad14"
                    }
                  }),
                  _vm._v(" " + _vm._s(_vm.$t("lbl_confirmation")) + " ")
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "payment-posting-close" },
                      on: { click: _vm.onClose }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
                  ),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        type: "primary",
                        "data-testid": "payment-posting-create"
                      },
                      on: { click: _vm.onSubmit }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("common.create-text", {
                              text: _vm.$t("lbl_cash_in_out")
                            })
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("p", { staticClass: "ant-form-text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("confirmation_trucking_payment_different_date")) +
            " "
        )
      ]),
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: { model: _vm.formState, rules: _vm.validationSchema }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_payment_date"), prop: "paymentDate" }
            },
            [
              _c("a-date-picker", {
                attrs: {
                  "allow-clear": "",
                  "data-testid": "payment-posting-date",
                  "disabled-date": _vm.disabledDate,
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.formState.paymentDate,
                  callback: function($$v) {
                    _vm.$set(_vm.formState, "paymentDate", $$v)
                  },
                  expression: "formState.paymentDate"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }