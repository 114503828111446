

























































































































































































































































import SelectPosting from "@/components/custom/select/SelectPosting.vue";
import PaymentDetailModal from "@/components/TruckingPayment/PaymentDetailModal.vue";
import PaymentPostingDateModal from "@/components/TruckingPayment/PaymentPostingDateModal.vue";
import { debounceProcess } from "@/helpers/debounce";
import {
  APagination,
  useBank,
  useBlob,
  useDate,
  useTruckingPayment,
} from "@/hooks";
import { TruckingPaymentMapper } from "@/mapper/TruckingPayment.mapper";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import {
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  ONE,
  PAGE_SIZE_OPTIONS,
} from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  DATE_FORMAT_YYYY_MM_DD,
  DATE_FORMAT_yyyy_MM_DD,
  DEFAULT_DATE_FORMAT,
} from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  TruckingPaymentDownloadParam,
  TruckingPaymentListFilterState,
  TruckingPaymentListParam,
  TruckingPaymentResponseDto,
} from "@/models/interface/trucking-payment";
import { SorterProps } from "@/types";
import { currencyFormat } from "@/validator/globalvalidator";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component({
  mixins: [MNotificationVue],
  components: {
    SelectPosting,
    PaymentDetailModal,
    PaymentPostingDateModal,
  },
})
export default class TruckingPayment extends Vue {
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;

  formModel!: FormModel;

  loading = {
    find: false,
    download: false,
    bank: false,
  };

  pagination = {
    page: +this.$route?.query.p || DEFAULT_PAGE,
    limit: +this.$route?.query.l || DEFAULT_PAGE_SIZE,
    reset(): void {
      this.page = DEFAULT_PAGE;
    },
  };

  payments: Pagination<TruckingPaymentResponseDto> = {
    currentPage: 0,
    data: [],
    totalElements: 0,
    totalPages: 0,
  };

  paymentAmountTotal = {
    total: "-",
    reset(): void {
      this.total = "-";
    },
  };

  paymentTableColumns = [
    {
      title: this.$t("lbl_payment_date"),
      dataIndex: "paymentDate",
      key: "paymentDate",
      sorter: true,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_payment_number"),
      dataIndex: "paymentNumber",
      key: "paymentNumber",
      sorter: true,
      scopedSlots: { customRender: "paymentNumber" },
    },
    {
      title: this.$t("lbl_ujo_number"),
      dataIndex: "ujoNumber",
      key: "ujoNumber",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_driver_name"),
      dataIndex: "driverName",
      key: "driverName",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_source_bank"),
      dataIndex: "sourceBank",
      key: "sourceBank",
      sorter: true,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_doc_reference"),
      dataIndex: "cashInOutNumber",
      key: "cashInOutNumber",
      sorter: true,
      scopedSlots: { customRender: "cashInOutNumber" },
    },
  ];

  filters: TruckingPaymentListFilterState = {
    paymentDate: [],
    paymentNumber: "",
    ujoNumber: "",
    driverName: "",
    posting: "",
    docReference: "",
    companyBank: undefined,
  };

  selectedPaymentIds: string[] = [];
  selectedPayments: TruckingPaymentResponseDto[] = [];

  detailModal = {
    visible: false,
    paymentNumber: "",
    reset(): void {
      this.visible = false;
      this.paymentNumber = "";
    },
  };

  postingModal = {
    visible: false,
    params: {
      paymentDate: null as null | Moment,
    },
  };

  bankOptions: Option[] = [];

  onChangeTable(pagination: APagination, _filter, sorts: SorterProps): void {
    const { current, pageSize } = pagination;
    const { columnKey, order } = sorts;

    this.pagination.page = current;
    if (pageSize !== this.pagination.limit) {
      this.pagination.page = DEFAULT_PAGE;
    }
    this.pagination.limit = pageSize;

    const params = this.buildQueryParams(this.filters);
    params.sortBy = columnKey;
    if (order) {
      params.direction = order === "ascend" ? "asc" : "desc";
    }

    this.updateQueryFilter();
    this.fetchPayments(params);
  }

  openPaymentDetailModal(paymentNumber: string): void {
    this.detailModal.paymentNumber = paymentNumber;
    this.detailModal.visible = true;
  }

  get rowSelection() {
    return {
      selectedRowKeys: this.selectedPaymentIds,
      onChange: (selectedRowKeys: string[]) => {
        this.selectedPaymentIds = selectedRowKeys;
      },
      onSelect: (record: TruckingPaymentResponseDto, selected: boolean) => {
        if (selected) {
          this.selectedPayments.push(record);
        } else {
          this.selectedPayments = [...this.selectedPayments].filter(
            item => item.id !== record.id
          );
        }
      },
      onSelectAll: (
        _selected: boolean,
        selectedRows: TruckingPaymentResponseDto[]
      ) => {
        this.selectedPayments = selectedRows;
      },
      getCheckboxProps: (record: TruckingPaymentResponseDto) => ({
        props: {
          disabled: !!record.cashInOutNumber,
        },
      }),
    };
  }

  updateQueryFilter(): void {
    this.$router.replace({
      name: "trucking.payment",
      query: {
        p: this.pagination.page.toString(),
        l: this.pagination.limit.toString(),
      },
    });
  }

  fetchPayments(params: TruckingPaymentListParam): void {
    const { findListPayments } = useTruckingPayment();

    this.loading.find = true;
    findListPayments(params)
      .then(res => {
        this.payments = res;
        this.paymentAmountTotal.total = currencyFormat(res.totalAmount);
      })
      .finally(() => {
        this.loading.find = false;
      });
  }

  buildQueryParams(
    state: TruckingPaymentListFilterState
  ): TruckingPaymentListParam {
    const { toStartDay, toEndDay } = useDate();

    const params: TruckingPaymentListParam = {
      cashInOutNumber: state.docReference ?? undefined,
      driverName: state.driverName ?? undefined,
      limit: this.pagination.limit,
      page: this.pagination.page - ONE,
      paymentNumber: state.paymentNumber ?? undefined,
      ujoNumber: state.ujoNumber ?? undefined,
      isPosted: state.posting ?? undefined,
    };

    if (state.paymentDate && state.paymentDate.length > 0) {
      const [start, end] = state.paymentDate;

      params.paymentDateStart = toStartDay(start).format(
        DATE_FORMAT_yyyy_MM_DD
      );
      params.paymentDateEnd = toEndDay(end).format(DATE_FORMAT_yyyy_MM_DD);
    }

    if (state.companyBank) {
      params.companyBank = state.companyBank.key;
    }

    return params;
  }

  handleReset(): void {
    this.pagination.reset();
    this.formModel.resetFields();

    const params = this.buildQueryParams(this.filters);
    this.fetchPayments(params);
    this.getBankList();
  }

  handleFind(): void {
    this.pagination.reset();
    const params = this.buildQueryParams(this.filters);

    this.fetchPayments(params);
  }

  handlePosting(): void {
    const bankIds = new Set<string>();
    const paymentDates = new Set<string>();
    const records = [...this.selectedPayments];
    records.sort((a, b) => {
      if (new Date(a.paymentDate) < new Date(b.paymentDate)) return -1;
      if (new Date(a.paymentDate) > new Date(b.paymentDate)) return 1;
      return 0;
    });
    records.forEach(item => {
      bankIds.add(item.sourceBankId);
      paymentDates.add(moment(item.paymentDate).format());
    });
    if (bankIds.size > 1) {
      this.showNotifWarning("notif_trucking_payment_invalid_bank");
      return;
    }

    if (paymentDates.size > 1) {
      this.postingModal.visible = true;
      this.postingModal.params.paymentDate = moment([...paymentDates][0]);
      return;
    }

    this.goToCashOut({
      ids: [...this.selectedPaymentIds],
      paymentDate: moment([...paymentDates][0]).format(DATE_FORMAT_YYYY_MM_DD),
    });
  }

  handleDownload(): void {
    const { downloadListPayment } = useTruckingPayment();
    const { toDownload } = useBlob();

    const params = this.buildQueryParams(this.filters);
    params.limit = this.payments.totalElements;
    params.page = 0;

    const downloadParams: TruckingPaymentDownloadParam =
      TruckingPaymentMapper.paginationParamToParamDownload(
        this.filters,
        params
      );

    this.loading.download = true;
    downloadListPayment(downloadParams)
      .then(res => {
        toDownload(res, "trucking_payment.xlsx");
      })
      .finally(() => {
        this.loading.download = false;
      });
  }

  mounted(): void {
    this.onSearchBank = debounceProcess(this.onSearchBank, 500);

    if (this.$refs.formModel) {
      this.formModel = this.$refs.formModel as FormModel;
    }

    const params = this.buildQueryParams(this.filters);
    this.fetchPayments(params);

    this.getBankList();
  }

  onConfirmPosting({ formState }: { formState: { paymentDate: Moment } }) {
    const ids: string[] = [...this.selectedPaymentIds];
    this.goToCashOut({
      ids,
      paymentDate: formState.paymentDate.format(DATE_FORMAT_YYYY_MM_DD),
    });
  }

  goToCashOut({ paymentDate, ids }: { paymentDate: string; ids: string[] }) {
    this.$router.push({
      name: "trucking.cash-in-out.create",
      query: {
        paymentDate,
        ids,
        payment: "true",
      },
    });
    this.postingModal.visible = false;
  }

  async getBankList(params?: RequestQueryParamsModel) {
    const { findAll, toOptions } = useBank();
    try {
      this.loading.bank = true;
      const response = await findAll(params);
      this.bankOptions = toOptions(response.data);
    } finally {
      this.loading.bank = false;
    }
  }

  onSearchBank(value?: string) {
    const { searchBy } = useBank();
    const params = new RequestQueryParams();

    if (value) {
      params.search = searchBy({
        bankAccName: value,
        bankAccNumber: value,
        bankName: value,
      });
    }

    this.getBankList(params);
  }
}
