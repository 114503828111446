


















































import { useDisabledFromTomorrow, useMinByDate } from "@/hooks";
import MNotification from "@/mixins/MNotification.vue";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import Vue from "vue";

export default Vue.extend({
  name: "PaymentPostingDateModal",
  mixins: [MNotification],
  props: {
    value: Boolean,
    params: Object as () => { paymentDate: Moment },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      formState: {
        paymentDate: null as Moment | null,
      },
      validationSchema: {
        paymentDate: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        const form = this.$refs.form as FormModel | null;
        if (form) {
          await form.validate();
          this.$emit("confirm", { formState: this.formState });
        }
      } catch (error) {
        this.showNotifError("notif_validation_error");
      }
    },
    onClose() {
      this.$emit("input", false);
      this.$emit("close", false);
    },
    disabledDate(date: Moment) {
      return (
        useMinByDate(date, this.params.paymentDate) ||
        useDisabledFromTomorrow(date)
      );
    },
  },
});
